import { useMemo, useState } from 'react';
import { DataProviderProxy } from 'react-admin';
import some from 'lodash/some';
import isArray from 'lodash/isArray';
import intersection from 'lodash/intersection';
import mapValues from 'lodash/mapValues';
import includes from 'lodash/includes';
import { createContainer } from 'unstated-next';
import { AppRole } from './roles';
import { AppModules } from 'customizations/constants';
import * as permissions from './permissions';

type RoleCheckFunction = (role: AppRole) => boolean;

export const checkPermissions = (
  checkFn: RoleCheckFunction,
): Record<string, boolean> => ({
  [permissions.CAN_EDIT_SUPPLIER]: checkFn(AppRole.MaterialDispatcher),
  [permissions.CAN_SEE_TOOLBAR]:
    checkFn(AppRole.LaborCoordinator) ||
    checkFn(AppRole.MaterialDispatcher) ||
    checkFn(AppRole.CustomerServiceRepresentative),
  [permissions.CAN_SEE_SP_POTENTIAL_MATCHES]:
    (checkFn(AppRole.LaborCoordinator) ||
      checkFn(AppRole.MaterialDispatcher)) &&
    checkFn(AppRole.SupplyProTester),
  [permissions.CAN_EDIT_SP_POTENTIAL_MATCHES]:
    (checkFn(AppRole.LaborCoordinator) ||
      checkFn(AppRole.MaterialDispatcher)) &&
    checkFn(AppRole.SupplyProTester),
  [permissions.CAN_EDIT_BUILDER]:
    checkFn(AppRole.MaterialDispatcher) || checkFn(AppRole.SupplyProTester),
  [permissions.CAN_EDIT_MODEL]:
    checkFn(AppRole.MaterialDispatcher) || checkFn(AppRole.SupplyProTester),
  [permissions.CAN_EDIT_REGION]: checkFn(AppRole.MaterialDispatcher),
  [permissions.CAN_EDIT_CONTRACTOR]:
    checkFn(AppRole.MaterialDispatcher) || checkFn(AppRole.LaborCoordinator),
  [permissions.CAN_ASSIGN_CONTRACTOR]: checkFn(AppRole.RegionalManager),
  [permissions.CAN_APPROVE_BUMPOUT]: checkFn(AppRole.Supervisor),
  [permissions.CAN_LOG_HOURS_BUMPOUT]:
    checkFn(AppRole.Bumper) || checkFn(AppRole.CustomerServiceRepresentative),
  [permissions.CAN_SEE_CONTRACTORS_BUMPOUT]:
    checkFn(AppRole.Supervisor) ||
    checkFn(AppRole.CustomerServiceRepresentative),
  [permissions.CAN_EDIT_SUB_DIVISION]:
    checkFn(AppRole.MaterialDispatcher) ||
    checkFn(AppRole.LaborCoordinator) ||
    checkFn(AppRole.CustomerServiceRepresentative),
  [permissions.CAN_EDIT_NOTES]:
    checkFn(AppRole.RegionalManager) ||
    checkFn(AppRole.Supervisor) ||
    checkFn(AppRole.MaterialDispatcher),
  [permissions.CAN_EDIT_OPTIONS]: checkFn(AppRole.Supervisor),
  [permissions.CAN_OVERRIDE_CONTRACTOR]: checkFn(AppRole.RegionalManager),
  [permissions.CAN_REQUEST_RESOURCE]: checkFn(AppRole.Supervisor),
  [permissions.CAN_REQUEST_RESOURCE_ASSIGNED]:
    checkFn(AppRole.Supervisor) || checkFn(AppRole.RegionalManager),
  [permissions.CAN_TOGGLE_GARAGE]: checkFn(AppRole.Supervisor),
  [permissions.CAN_APPROVE_PHASE]: checkFn(AppRole.Supervisor),
  [permissions.CAN_SIGN_OFF]: checkFn(AppRole.Supervisor),
  [permissions.CAN_PAID_PHASE]:
    checkFn(AppRole.LaborCoordinator) ||
    checkFn(AppRole.CustomerServiceRepresentative),
  [permissions.CAN_EDIT_PO_RELEASED]:
    checkFn(AppRole.LaborCoordinator) ||
    checkFn(AppRole.CustomerServiceRepresentative),
  [permissions.CAN_EDIT_BUMPOUT_TIMESHEET_PAID]: checkFn(
    AppRole.LaborCoordinator,
  ),
  [permissions.CAN_CHANGE_JOB]: checkFn(AppRole.MaterialDispatcher),
  [permissions.CAN_EDIT_OPTIONS_JOB_VIEW]: checkFn(AppRole.MaterialDispatcher),
  [permissions.CAN_CHECK_COUNT]:
    checkFn(AppRole.CustomerServiceRepresentative) ||
    checkFn(AppRole.MaterialDispatcher) ||
    checkFn(AppRole.Supervisor),
  [permissions.CAN_ADD_NOTE_CHECK_COUNT]: checkFn(AppRole.Supervisor),
  [permissions.CAN_CHECK_HOUSE]:
    checkFn(AppRole.Supervisor) ||
    checkFn(AppRole.RegionalManager) ||
    checkFn(AppRole.CustomerServiceRepresentative) ||
    checkFn(AppRole.MaterialDispatcher),
  [permissions.CAN_CHECK_UNCHECK_HOUSE]:
    checkFn(AppRole.Supervisor) || checkFn(AppRole.RegionalManager),
  [permissions.CAN_CREATE_REPAIR]:
    checkFn(AppRole.CustomerServiceRepresentative) ||
    checkFn(AppRole.Supervisor),
  [permissions.CAN_CREATE_BOARD]:
    checkFn(AppRole.Admin) || checkFn(AppRole.SuperAdmin),
  [permissions.CAN_CREATE_ROUTE]:
    checkFn(AppRole.Admin) || checkFn(AppRole.SuperAdmin),
  [permissions.CAN_SEND_EMAIL_CLIENT]:
    checkFn(AppRole.Supervisor) ||
    checkFn(AppRole.CustomerServiceRepresentative),
  [permissions.CAN_SEE_SCHEDULE]: checkFn(AppRole.Supervisor),
  [permissions.CAN_CANCEL_PO]: checkFn(AppRole.MaterialDispatcher),
  [permissions.CAN_SEE_MATERIALS_DELIVERY]:
    checkFn(AppRole.SupplierRepresentative) ||
    checkFn(AppRole.MaterialDispatcher) ||
    checkFn(AppRole.LaborCoordinator) ||
    checkFn(AppRole.CustomerServiceRepresentative) ||
    checkFn(AppRole.AccountingViewOnly),
  [permissions.CAN_ADD_MATERIALS_DELIVERY_DATE]: checkFn(
    AppRole.SupplierRepresentative,
  ),
  [permissions.CAN_CHANGE_EXPECTED_DATE]: checkFn(AppRole.MaterialDispatcher),
  [permissions.CAN_ADD_PARTIAL_DELIVERY]: checkFn(
    AppRole.SupplierRepresentative,
  ),
  [permissions.CAN_STOP_FULFILLMENT]: checkFn(AppRole.MaterialDispatcher),
  [permissions.CAN_PRIORITIZE_MATERIALS_DELIVERY]: checkFn(
    AppRole.MaterialDispatcher,
  ),
  [permissions.CAN_CANCEL_DELIVERY_PURCHASE_ORDER]: checkFn(
    AppRole.MaterialDispatcher,
  ),
  [permissions.CAN_ADD_REPAIR_CONTRACTOR]:
    checkFn(AppRole.Supervisor) ||
    checkFn(AppRole.CustomerServiceRepresentative),
  [permissions.CAN_CHANGE_DATES_MASTER_SHEET]: checkFn(AppRole.Supervisor),
  [permissions.CAN_IMPORT_JOBS]:
    (checkFn(AppRole.LaborCoordinator) ||
      checkFn(AppRole.MaterialDispatcher)) &&
    checkFn(AppRole.SupplyProTester),
  [permissions.CAN_MARK_SPRAYING_READY_NOW]: checkFn(AppRole.Supervisor),
  [permissions.CAN_ADD_REPAIR_NOTES]: checkFn(
    AppRole.CustomerServiceRepresentative,
  ),
  [permissions.CAN_SEE_LOT_REPORT]:
    checkFn(AppRole.CustomerServiceRepresentative) ||
    checkFn(AppRole.LaborCoordinator),
  [permissions.CAN_TOGGLE_SUBDIVISION_BUMPOUT_TIMESHEET_FLOW]:
    checkFn(AppRole.Admin) || checkFn(AppRole.SuperAdmin),
  [permissions.CAN_EDIT_BUMPOUT_TIMESHEET_NOTES]:
    checkFn(AppRole.Bumper) ||
    checkFn(AppRole.Supervisor) ||
    checkFn(AppRole.CustomerServiceRepresentative) ||
    checkFn(AppRole.LaborCoordinator),
  [permissions.CAN_EDIT_BUMPOUT_TIMESHEET_PHOTOS]:
    checkFn(AppRole.Supervisor) || checkFn(AppRole.Bumper),
  [permissions.CAN_LOG_GARAGE]: checkFn(AppRole.Supervisor),
  [permissions.CAN_DELETE_BUMP_IMAGES]:
    checkFn(AppRole.Supervisor) || checkFn(AppRole.Bumper),
  [permissions.CAN_EXPORT_ACCOUNTING_REPORT]: checkFn(
    AppRole.AccountingViewOnly,
  ),
});

const usePermissions = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<any>();
  const [userRoles, setUserRoles] = useState<any[]>([]);

  const roles = useMemo(() => userRoles.map(role => role.name), [userRoles]);

  const hasRole = (role: AppRole[] | AppRole) => {
    if (!userRoles.length) return false;

    if (!isArray(role)) return roles.some(roleName => roleName === role);

    return some(role, item => {
      if (isArray(item)) {
        return intersection(item, roles).length === item.length;
      }
      return includes(roles, item);
    });
  };

  const rules = checkPermissions(hasRole);

  const flattenedModules = mapValues(permissions.modulesPermissions, module => {
    if (module.edit) {
      const canEdit = hasRole(module.edit) ?? false;
      return {
        list: canEdit ? canEdit : module.list ? hasRole(module.list) : false,
        edit: canEdit,
      };
    }

    return {
      list: module.list ? hasRole(module.list) : false,
      edit: false,
    };
  });

  const getUser = async (userId: string, dataProvider: DataProviderProxy) => {
    try {
      const userData = await dataProvider.getOne('user', { id: userId });
      const rolesAssociated = await dataProvider.getList('user-role', {
        filter: { userId },
        pagination: { page: 1, perPage: 10 },
        sort: { field: 'id', order: 'ASC' },
      });
      if (rolesAssociated) {
        const rolesId = rolesAssociated.data.map((role: any) => role.roleId);
        const roles = await dataProvider.getList('role', {
          filter: { 'id||$in||': rolesId.join() },
          pagination: { page: 1, perPage: 10 },
          sort: { field: 'id', order: 'ASC' },
        });
        if (userData) {
          setUserRoles(roles.data);
          setUser(userData.data);
        }
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const hasPermission = key =>
    hasRole([AppRole.SuperAdmin, AppRole.Admin]) || rules[key];

  const hasModulePermission = (
    moduleName: AppModules,
    accessType = permissions.PermType.List,
  ) => flattenedModules[moduleName][accessType];

  return {
    user,
    getUser,
    userRoles,
    hasPermission,
    hasModulePermission,
    hasRole,
    loading,
  };
};

export const PermissionsProvider = createContainer(usePermissions);
