import { useEffect } from 'react';
import {
  Layout,
  AppBar,
  AppBarProps,
  useDataProvider,
  Loading,
} from 'react-admin';
import {
  AppLocationContext,
  Breadcrumb,
  ResourceBreadcrumbItems,
} from '@react-admin/ra-navigation';

import { makeStyles } from '@material-ui/core/styles';
import ImageLogo from 'assets/logo.png';
import CustomMenu from './CustomMenu';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { UIProvider } from 'providers/uiProvider';

const useStyles = makeStyles({
  title: {
    color: 'black',
    flex: 1,
  },
  navbarRoot: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    display: 'flex',
    margin: '5px 0px',
  },
  appBar: {
    color: 'black',
    backgroundColor: 'white',
  },
  appBarOrange: {
    color: 'black',
    backgroundColor: 'orange',
  },
  breadCrumb: {
    marginTop: '20px',
    marginBottom: '24px',
  },
  logo: {
    width: '120px',
    aspectRatio: '5/2',
  },
});

export const ENV = process.env.REACT_APP_ENV || 'DEV';

const CustomAppBar: React.FC = (props: AppBarProps) => {
  const { setOpened } = UIProvider.useContainer();
  const classes = useStyles();

  useEffect(() => {
    setOpened(!!props.open);
  }, [props.open]);

  return (
    <AppBar
      className={ENV === 'SP' ? classes.appBarOrange : classes.appBar}
      {...props}
    >
      <div className={classes.navbarRoot}>
        <img src={ImageLogo} className={classes.logo} />
      </div>
    </AppBar>
  );
};

const CustomLayout: React.FC = (props: AppBarProps) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const { getUser, loading } = PermissionsProvider.useContainer();

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    getUser(String(userId), dataProvider);
  }, []);

  if (loading) return <Loading loadingPrimary="" loadingSecondary="" />;

  return (
    <AppLocationContext>
      <Layout {...props} appBar={CustomAppBar} menu={CustomMenu}>
        <Breadcrumb {...props} className={classes.breadCrumb}>
          <ResourceBreadcrumbItems />
        </Breadcrumb>
        {props.children}
      </Layout>
    </AppLocationContext>
  );
};

export default CustomLayout;
